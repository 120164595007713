import React from "react"
import { Link } from "react-router-dom"




function Header(){
    
    return(
        <div style={{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center',
            background:'rgb(3 13 21)',
            padding:'20px',
            borderRadius: "0 0 32px 32px",
            boxShadow:'-2px 3px 6px -1px rgb(251 251 251 / 27%)'
        }}>
            <p style={{
                fontSize:'25',
                fontWeight:'bold',
                letterSpacing:'3px',
                color:'#fff'
            }}> Eccomerce</p>

            <div style={{
                display:'flex',
                gap:'20px',
                alignItems:"center"
            }}>
                <Link style={{color:'#fff'}} to='/'>Home</Link>
                {/* {!token ? <> */}
                <Link style={{color:'#fff'}} to='/login'>Login</Link>
                <Link style={{color:'#fff'}} to='/register'>Register</Link>
                <Link style={{color:'#fff'}} to='/add-product'>Add Product</Link>
                <Link style={{color:'#fff'}} to='/My-product'>My Product</Link>
                {/* </> : */}
                {/* <> */}
                <button style={{padding:10,  borderRadius: "16px",backgroundColor:"black", color:"white", borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'}} >Logout</button>
                {/* </>} */}
                <button style={{padding:10, borderRadius: "16px",backgroundColor:"black", color:"white", borderWidth: '2px', borderBlockColor: 'red', borderBlockStartColor: 'blue'}}>Cart </button>
            </div>

        </div>
    )
}

export default Header