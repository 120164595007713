import React from "react"

function Footer(){
    return(
      
     
            <p style={{
                padding: 20,
                background:'rgb(3 13 21)',
                color:'#fff',
                textAlign:'center',
                borderRadius: "32px 32px 0 0",
                boxShadow:'rgba(251, 251, 251, 27) 0px 13px 14px 7px'
            }}>Eccomerce &#169; 2024 </p>

    )
}
export default Footer