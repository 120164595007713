import React, { useEffect, useState } from "react"

function ProductDetail(){
  return(
        <div style={{display: 'flex', gap: 20, justifyContent: 'space-around', flex: 1, padding: 20,}}>
                 <img style={{width: '30%', height: 400}} src={{}} alt="product detail" />
            <div style={{display: 'flex', flexDirection: 'column', gap: 20, background: '#030319',width: 250,margin: '16px', padding:'10px', boxShadow:'rgb(1 49 134 / 64%) -12px 10px 24px -4px', borderRadius:'10px',alignSelf: "start"}}>
                <h1>{}</h1>
                <h4>By {}</h4>
                <p>ETB {}</p>
                <button style={{
                         padding:10,
                         textAlign:"center",
                         textDecoration:'none',
                         background:"#000",
                         color:'#fff',
                        borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'
                     
                }}>Add To Cart</button>
            </div>
        </div>
    )
}
export default ProductDetail