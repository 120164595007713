import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './router'
import appContext from './appContext';
import { useEffect, useState } from 'react';

function App() {
  const [products , setProducts] = useState([])

  const [cart, setCart] = useState([])
  const [cartVisible, setCartVisible] = useState(false)

  const [token, setToken ]= useState(null)

  useEffect(()=>{
    let tok = localStorage.getItem('token')
    if(tok){
      setToken(tok)
    }
  },[])

  return (
    <>
    <appContext.Provider  value={{cart, setCart , cartVisible ,setCartVisible, token, setToken ,products , setProducts}}>
      <RouterProvider router = {router} />
    </appContext.Provider >
    </>
  );
}

export default App;
