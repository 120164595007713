import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import appContext from '../appContext'
import { Link } from 'react-router-dom'
import ProductCart from '../components/ProductCart'

function Myproduct() {
    const {token} = useContext(appContext)
    const [products, setProducts] = useState([])
    useEffect( () => {
        axios("http://localhost:4000/my-product", {
            headers: {
                Authorization: 'Bearer ' + token,
            
             }
        }).then(response => {
            console.log(response.data)
            setProducts(response.data.products)
        }).catch(e => '')

    },[token])
  return (
    <div style={{display: 'flex', flex:1 ,flexDirection: 'column'}}>

   
    <div style={{display: 'flex', gap:'13px' ,flexDirection: 'column', background: '#030319',width: 250,margin: '16px', padding:'10px', boxShadow:'rgb(1 49 134 / 64%) -12px 10px 24px -4px', borderRadius:'10px',  
    alignSelf: "start"}}>
        {/* <img style={{width:'100%', borderRadius:10}} src={{}} alt="product" />

        <h2> products</h2>
        <p style={{marginTop: 15}}> ETB {} </p>
        <p style={{fontSize: 12}}>by {} </p> */}

        {/* <div style={{display:'flex', width:'100%', gap:10}}>
            <Link to={'/product-detail'}  style={{
                flex: 1,
                padding:10,
                textAlign:"center",
                textDecoration:'none',
                background:"none",
                color:'#fff',
                border:'solid',
                borderStyle: 'outset',
                borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'
            }}> 
                See detail</Link>
            <button onClick={{}} style={{
                flex: 1,
                padding:10,
                textAlign:"center",
                textDecoration:'none',
                background:"#000",
                color:'#fff',
               borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'
            }}>Add to cart </button>
            
           <button onClick={{}} style={{
                flex: 1,
                padding:10,
                textAlign:"center",
                textDecoration:'none',
                background:"#000",
                color:'#fff',
                borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'
            }}>Delete</button>
            
           
        </div> */}
         <div style={{
        flex:1,
            display:'flex',
            gap:'20px',
            padding:'20px',
            display: "grid",  
            gridTemplateColumns: "1fr 1fr 1fr"
        }}>
        {products.map (d => <ProductCart detail={d} setProducts={setProducts} products={products}/> )} 
        </div>
    </div>


    </div>
  )
}

export default Myproduct