import React, { useContext, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from "axios";
import appContext from "../appContext";
import ProductCart from "../components/ProductCart";

function Home() {
    const {setProducts, products} = useContext(appContext)
    
    useEffect( () => {
        axios("http://localhost:4000/all-product").then(response => {
            console.log(response.data)
            setProducts(response.data.products)
        })

    },[])
  return (
    // <div style={{display: 'flex', flex:1 ,flexDirection: 'column'}}>

   
    // <div style={{display: 'flex', gap:'13px' ,flexDirection: 'column', background: '#030319',width: 250,margin: '16px', padding:'10px', boxShadow:'rgb(1 49 134 / 64%) -12px 10px 24px -4px', borderRadius:'10px',  
    // alignSelf: "start"}}>
    //     <img style={{width:'100%', borderRadius:10}} src={{}} alt="product" />

    //     <h2> products</h2>
    //     <p style={{marginTop: 15}}> ETB {} </p>
    //     <p style={{fontSize: 12}}>by {} </p>

    //     <div style={{display:'flex', width:'100%', gap:10}}>
    //         <Link to={'/product-detail'}  style={{
    //             flex: 1,
    //             padding:10,
    //             textAlign:"center",
    //             textDecoration:'none',
    //             background:"none",
    //             color:'#fff',
    //             border:'solid',
    //             borderStyle: 'outset',
    //             borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'
    //         }}> 
    //             See detail</Link>
    //         <button onClick={{}} style={{
    //             flex: 1,
    //             padding:10,
    //             textAlign:"center",
    //             textDecoration:'none',
    //             background:"#000",
    //             color:'#fff',
    //            borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'
    //         }}>Add to cart </button>
            
    //        <button onClick={{}} style={{
    //             flex: 1,
    //             padding:10,
    //             textAlign:"center",
    //             textDecoration:'none',
    //             background:"#000",
    //             color:'#fff',
    //             borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'
    //         }}>Delete</button>
            
           
    //     </div>
    // </div>


    // </div>
    <div style={{
        flex:1,
        display:'flex',
        gap:'20px',
        padding:'20px',
        display: "grid",  
        gridTemplateColumns: "1fr 1fr 1fr"
    }}>
       {products.map (d => <ProductCart detail={d} /> )} 
    </div>


  )
}

export default Home