import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate  } from 'react-router-dom'
import axios from 'axios'
import appContext from "../appContext";

function Login() {
  const {setToken} = useContext(appContext)

  const navigate = useNavigate()

  const onsubmit = (event) => {
    event.preventDefault()
    axios('http://localhost:4000/login',{
      method: 'post',
      data:{
        username: event.target.username.value,
        password: event.target.password.value
      }
    }).then(response =>{
      if(response.data.success){
          localStorage.setItem('token', response.data.token)
          setToken(response.data.token)

      navigate('/')
      }
      
    else
    alert("incorrect email or password")
    })
  }

  return (
    <div style={{display: 'flex', flex:1, justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
        <form onSubmit={onsubmit} style={{display: 'flex',flexDirection: 'column', width: '50vw', padding: '40px', borderRadius: '12px', background: 'rgb(97, 108, 117)', gap: '26px'}}>
        <h2 style={{display: 'flex', justifyContent: 'center'}}>welcom back!</h2>
        <input style={{padding: '12px',border: 'none',borderRadius: '10px', color: "#000"}} type='text' name='username' placeholder='user name'></input>
        <input style={{padding: '12px',border: 'none',borderRadius: '10px', color: "#000"}} type='text' name='password' placeholder='password'></input>
        <label>forgot password?</label>
        <button style={{display: 'flex', justifyContent: 'center',background:'#000', color:'#fff', padding: '11px',borderRadius: '12px'}} >login</button>
        <label>Don't have an account? <Link style={{color:'blue'}} to='/register'><span>Register</span></Link></label>
        </form>
        
   
    </div>
  )
}

export default Login