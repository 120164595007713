import React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import axios from 'axios'

function Register(props) {
  const navigate = useNavigate()

  const onsubmit = (event) => {
    event.preventDefault()
    axios('http://localhost:4000/register',{
      method: 'post',
      data:{
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        username: event.target.username.value,
        password: event.target.password.value
      }
    }).then(response =>{
      if(response.data.success)
      alert("sucessfully registord!")
    else
    alert("email is already taken")
    })
    navigate('')
  }
  return (
    <div style={{display: 'flex', flex:1, justifyContent: 'center', alignItems: 'center',flexDirection: 'column'}}>
    <form onSubmit={onsubmit} style={{display: 'flex',flexDirection: 'column', width: '50vw', padding: '40px', borderRadius: '12px', background: 'rgb(97, 108, 117)', gap: '26px'}}>
    <h2 style={{display: 'flex', justifyContent: 'center'}}>welcom to our website!</h2>
    <input style={{padding: '12px',border: 'none',borderRadius: '10px', color: "#000"}} type='text' name='first_name' placeholder='first name'></input>
    <input style={{padding: '12px',border: 'none',borderRadius: '10px', color: "#000"}} type='text' name='last_name' placeholder='last name'></input>
    <input style={{padding: '12px',border: 'none',borderRadius: '10px', color: "#000"}} type='text' name='username' placeholder='user name'></input>
    <input style={{padding: '12px',border: 'none',borderRadius: '10px', color: "#000"}} type='text' name='password' placeholder='password'></input>
    <label>forgot password?</label>
    <button style={{background:'#000', color:'#fff', padding: '11px',borderRadius: '12px'}} >Register</button>
    <label>already have an account?<Link style={{color:'blue'}} to='/login'><span> login </span></Link></label>

    </form>

</div>
  )
}

export default Register