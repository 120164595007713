// import React from 'react'

// function ProductCart() {
//   return (
//     <div style={{display: 'flex', flex:1 ,flexDirection: 'column',width: 250, padding:'10px', boxShadow:'-7px 16px 15px -3px rgba(0,0,0,0.1)', borderRadius:'10px',  
//     alignSelf: "start"}}>
//         <img style={{width:'100%', borderRadius:10}} src={{}} alt="product" />

//         <h2> products</h2>
//         <p style={{marginTop: 15}}> ETB {} </p>
//         <p style={{fontSize: 12}}>by {} </p>

//         <div style={{display:'flex', width:'100%', gap:10}}>
//             <Link style={{flex: 1, padding:10, textAlign:"center",textDecoration:'none',background:"none",color:'#000',border:'1px solid #000'
//             }}> 
//                 See detail</Link>
//             <button onClick={{}} style={{
//                 flex: 1,
//                 padding:10,
//                 textAlign:"center",
//                 textDecoration:'none',
//                 background:"#000",
//                 color:'#fff',
//                 border:'none'
//             }}>Add to cart </button>
            
//            <button onClick={{}} style={{
//                 flex: 1,
//                 padding:10,
//                 textAlign:"center",
//                 textDecoration:'none',
//                 background:"#fff",
//                 color:'#000'
//             }}>Delete</button>
            
           
//         </div>
//     </div>
//   )
// }

// export default ProductCart


import React from "react";
// import img from '../assets/isto.jpg'
import { Link } from "react-router-dom";
import { useContext } from "react";
import appContext from "../appContext";
import axios from "axios";



export default function ProductCart(props){
    const {setCart, cart ,products ,token} = useContext(appContext)

    const {title,price,company,id} = props.detail

    const deleteProduct = (event) =>{
        // or axios('http://localhost:4000/delete-product/' + id, {
        axios('http://localhost:4000/delete-product', {
            method: 'delete',
            // this will be removed if we use the first methode 
            data: { id },
            headers: {
                Authorization: 'Bearer ' + token
             }
         }).then(respond =>{
            if(respond.data.success)
            {
                alert('succesfuly deleted!')
                props.setProducts(props.products.filter(pr => pr.id != id))
            } 
         }).catch(e => alert('error')) 
    }

    
    return(
        <div style={{width: 250, padding:'10px', boxShadow:'-7px 16px 15px -3px rgba(0,0,0,0.1)', borderRadius:'10px',  
        alignSelf: "start"}}>
            <img style={{width:'100%', borderRadius:10}} src={{}} alt="product" />

            <h2> {title} </h2>
            <p style={{marginTop: 15}}> ETB {price} </p>
            <p style={{fontSize: 12}}>by {company} </p>

            <div style={{display:'flex', width:'100%', gap:10}}>
                <Link to={'/product-detail/' + id}  style={{
                    flex: 1,
                    padding:10,
                    textAlign:"center",
                    textDecoration:'none',
                    background:"none",
                    color:'#000',
                    border:'1px solid #000'
                }}> 
                    See detail</Link>
                <button onClick={()=> setCart([...cart, props.detail])} style={{
                    flex: 1,
                    padding:10,
                    textAlign:"center",
                    textDecoration:'none',
                    background:"#000",
                    color:'#fff',
                    border:'none'
                }}>Add to cart </button>
                
                {token && <button onClick={(event) => deleteProduct(event, props.id)} style={{
                    flex: 1,
                    padding:10,
                    textAlign:"center",
                    textDecoration:'none',
                    background:"#fff",
                    color:'#000'
                }}>Delete</button>}
                
               
            </div>
        </div>
    )
}