import {
    createBrowserRouter
} from 'react-router-dom'

import Layout from './pages/Layout'
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import ProductDetail from './pages/ProductDetail'
import AddProduct from './pages/AddProduct'
import MyProduct from './pages/Myproduct'

const router = createBrowserRouter([
    {
            path:'login',
            element:<Login />
        },
        {
            path:'Register',
            element:<Register />
        },
        { 
        path:'/',
        element:< Layout />,
        children:[
            {
                path:'',
                element:<Home />
            },
            {
                path:'/product-detail',
                element:<ProductDetail />
            },
            {
                path:'/add-product',
                element:<AddProduct/>
            },
            {
                path:'/My-product',
                element:<MyProduct />
            },
        ]
    }
])

export default router 