import React, { useContext } from 'react'
import axios from 'axios'
import appContext from '../appContext'



export default function AddProduct() {
    const {token} = useContext(appContext)
    const submitProduct = (event) => {
        event.preventDefault()
        if(!token) return alert('please login')
        let form = new FormData()
    form.append('title',event.target.title.value)
    form.append('company',event.target.company.value)
    form.append('price',event.target.price.value)
    form.append('description',event.target.description.value)
    form.append('image',event.target.image.files[0])

        axios('http://localhost:4000/add-product',{
         method: "post" ,
         data: form,
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/multipart-formdata'
         }
        })
       

    }
  return (
    <form onSubmit={submitProduct} style={{display: 'flex' , alignItems: 'center', justifyContent: 'center' , flex: 1, width: '380px', margin: '6% auto',background: '#262626',padding: '5px',overflow: 'hidden',borderRadius: '22px'}}>
       <div className='addproduct' style={{display: 'flex', alignItems: 'center', gap: 20, flexDirection: 'column', padding: 20}}>
        <input style={{color:'#000'}}  type='text' name='title'  placeholder='title' />
        <input style={{color:'#000'}} type='text' name='company' placeholder='company' />
        <input style={{color:'#000'}} type='text' name='price' placeholder='price' />
        <textarea  style={{color:'#000'}} name="description" placeholder='description'>

        </textarea>
        Image:<input type='file' name='image'/><br/>
        <input style={{width: 'fit-content',  flex: 1,padding:10,textAlign:"center",textDecoration:'none',background:"#000",color:'#fff',borderRadius: "16px",borderWidth: '2px', borderBlockColor: 'blue', borderBlockStartColor: 'red'}} type='submit' value='Addproduct'/>
        </div>
    </form>
  )
}